import styled from "styled-components";

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin: 23px 0 20px;

  & button {
    font-size: 14px;
  }
`;

export default ButtonGroup;