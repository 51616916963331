import React from "react";
import styled from "styled-components";

import { TableProvider } from '../store/table';
import Steps from './components/Steps/Steps';

const Wrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 50px auto;
  padding: 50px 0;
  border-top: 5px solid #000;
  border-bottom: 5px solid #000;

  @media (max-width: 1200px) {
      padding: 50px 20px;
  }
`;

const Header = styled.p`
  display: block;
  margin: 0 0 40px;
  font-size: 38px;
  line-height: 52px;
  color: #000;
  text-align: center;
`;

const OnPageApp = ({ venueId, endpoint, title, category, exclude }) => {

  return (
    <Wrapper className='theme--light'>
      <Header>{title}</Header>
      <TableProvider
        venueId={venueId}
        endpoint={endpoint}
        isOnPage
      >
        <Steps type={category} exclude={exclude} />
      </TableProvider>
    </Wrapper>
  );
};

export default OnPageApp;
