import Flickity from "react-flickity-component";
import React, { useState } from "react";
import styled from "styled-components";

import Popup from "../../../components/Popup/Popup";
import PopupTrigger from "../../../components/Popup/PopupTrigger/PopupTrigger"
import PopupView from "../../../components/Popup/PopupView/PopupView"
import Slide from "../../../components/ui/Slide";

const StyledFlickity = styled(Flickity)`
  & .flickity-button {
    height: 20px;
    width: 20px;
    background: none;
    color: white;

    &:disabled {
      opacity: 0 !important;
    }
  }
`;

const StyledPopupFlickity = styled(Flickity)`
  padding-right: 28px;
  padding-left: 28px;

  & .flickity-button {
    width: 34px;
    height: 34px;
    border: 1px solid #000;
    border-radius: 34px;
    transform: translateY(-50%) scale(0.85);

    &:disabled {
      opacity: 0;
    }

    > svg {
      transform: scale(0.6);
    }

    &.next {
      right: -13px;
    }

    &.previous {
      left: -13px;
    }
  }
`;

export const Hero = styled.div`
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  color: white;
`;

const HeroOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & > * {
    margin: 0;
    position: relative;
    z-index: 2;
  }

  & h3 {
    margin-bottom: 21px;
    font-weight: bold;
    font-size: 13px;
    line-height: 19px;
    color: white;
  }

  & p {
    font-size: 12px;
    line-height: 14px;
    color: white;
  }

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 120px;
    content: "";
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.9) 0%,
      transparent 100%
    );
  }
`;

const StyledRoundel = styled.button`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 158px;
    height: 34px;
    padding-right: 5px;
    padding-left: 5px;
    color: white;
    font-size: 12px;
    cursor: pointer;

    &,
    &:hover {
      background: var(--colour-tertiary-dmn);
    }
`;

const PackageHero = ({ active, click, data, hasOverlayText, roundel }) => {
  const {
    description,
    desktopImageUrl1,
    desktopImageUrl2,
    desktopImageUrl3,
    popupText,
    price,
    subtitle,
    title,
  } = data;

  const firstImgUrl = desktopImageUrl1 !== null ? desktopImageUrl1 : "https://youngscorporate-legacy.standard.aws.prop.cm/youngs/uploads/sites/2/2021/07/square.jpg";

  const [popupOpen, setPopupOpen] = useState(false);

  const handlePopupOpen = () => setPopupOpen(true);

  return (
    <>
      <Hero>
        {typeof roundel !== "undefined" && (
          <StyledRoundel onClick={handlePopupOpen} type="button">
            {roundel.text}
            {roundel.popup && <PopupTrigger>i</PopupTrigger>}
          </StyledRoundel>
        )}
        <StyledFlickity options={{ pageDots: false }}>
          <Slide>
            <img src={firstImgUrl} />
          </Slide>
          {desktopImageUrl2 && (
            <Slide>
              <img src={desktopImageUrl2} />
            </Slide>
          )}
          {desktopImageUrl3 && (
            <Slide>
              <img src={desktopImageUrl3} />
            </Slide>
          )}
        </StyledFlickity>
        {hasOverlayText ? (
          <HeroOverlay>
            <div>
              {title && <h3>{title}</h3>}
              {subtitle && <p>{subtitle}</p>}
              {price && <p>£{price}</p>}
            </div>
            {(description || popupText) && <PopupTrigger onClick={handlePopupOpen}>i</PopupTrigger>}
          </HeroOverlay>
        ) : (
          <HeroOverlay />
        )}

      </Hero>
      {popupOpen && (
        <Popup handleClose={() => setPopupOpen(false)}>
          {typeof roundel !== "undefined" && roundel.popup.length > 1 && (
            <StyledPopupFlickity options={{ pageDots: false }}>
              {roundel.popup.map((view) => <PopupView data={view} hideButton key={`popup-view-${view.id}`} />)}
            </StyledPopupFlickity>
          )}
          {typeof roundel !== "undefined" && roundel.popup.length === 1 && (
            <PopupView data={roundel.popup[0]} hideButton />
          )}
          {typeof roundel === "undefined" && (
            <PopupView active={active} click={click} data={data} />
          )}
        </Popup>
      )}
    </>
  );
};

export default PackageHero;
