import { addDays, eachDayOfInterval, format, isAfter, isBefore, isSameDay, parse } from "date-fns";
import React, { useContext, useCallback } from "react";
import styled from "styled-components";

import Button from "../ui/Button";
import ButtonGroup from "../ui/ButtonGroup";
import { dateFormat, weekdays } from "../../../helper";
import EndTimeField from "../ui/EndTimeField";
import Errors from "../../../components/ui/Errors";
import Field from "../ui/Field";
import Flatpickr from "../../../components/ui/Flatpickr";
import Grid from "../../../components/ui/Grid";
import GuestField from "../ui/GuestField";
import StartTimeField from "../ui/StartTimeField";
import { tableStore } from '../../../store/table';
import Text from "../../../components/ui/Text";
import areIntervalsOverlappingWithOptions from "date-fns/fp/areIntervalsOverlappingWithOptions/index.js";


const Label = styled.label`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.7px;
  margin-bottom: 5px;
`;

const SmallField = styled.div`
  width: 100%;
  max-width: 150px;

  @media (max-width: 500px) {
      max-width: 200px;
  }
`;

const BookingDetails = () => {

  const today = (new Date()).toISOString().split("T")[0];

  const { table, tableDispatch, setup, actions } =
    useContext(tableStore);


  const getAlertText = (bookingType) => {
    if (bookingType && typeof bookingType === "object") {

      if (((!bookingType.alertText || bookingType.alertText === null) && bookingType.showDefaultAlertText)) {
        return "Please note we keep an allocation of tables available for walk ins on the day so do pop by and we will always try and accommodate you.";
      }

      return bookingType.alertText;
    }

    return "";
  }

  const alertText = getAlertText(table.bookingType);

  const getEnabledDates = useCallback((overrides = table.overrides, rules = table.rules) => {
    const alwaysEnabled = new Set();
    const alwaysDisabled = new Set();

    overrides.forEach((override) => {
      const { available } = override;

      const overrideDates = eachDayOfInterval({ start: new Date(`${override.dateFrom}T00:00:00`), end: new Date(`${override.dateTo}T23:59:59`) });

      if (available) {
        overrideDates.forEach((date) => alwaysEnabled.add(format(date, "yyyy-MM-dd")));
      } else {
        overrideDates.forEach((date) => alwaysDisabled.add(format(date, "yyyy-MM-dd")));
      }
    });

    const weekdayIndexes = [];

    Object.values(rules).forEach((rule) => {
      const { available, dayOfWeek } = rule;

      if (available && dayOfWeek) {
        const weekdayIndex = weekdays.findIndex((weekday) => weekday === dayOfWeek);

        if (weekdayIndex > -1) {
          weekdayIndexes.push(weekdayIndex);
        }
      }
    });

    return [
      (date) => {
        const dateStr = format(date, "yyyy-MM-dd");

        if (!alwaysDisabled.has(dateStr) && (alwaysEnabled.has(dateStr) || weekdayIndexes.includes(date.getDay()))) {
          return true;
        }

        return false;
      },
    ];
  }, [table]);

  const handleDateChange = (date) => {
    tableDispatch([
      { type: "SET_API_ENABLED", data: true },
      ...(typeof date !== "undefined" ? [{ type: "SET_DATE", data: date }] : []),
    ]);
  };

  const handleGuestsChange = ({ guests, nonInterraction = false }) => {
    if (!nonInterraction) {
      actions.sendAnalyticsData({
        hitType: "event",
        eventAction: "Selection",
        eventLabel: `Guests - ${guests}`,
      });
    }

    tableDispatch({
      type: "SET_GUESTS",
      data: guests,
    });
  };

  const handleTimeStartChange = ({ time, nonInterraction = false }) => {
    if (!nonInterraction) {
      actions.sendAnalyticsData({
        hitType: "event",
        eventAction: "Selection",
        eventLabel: `Start Time - ${time}`,
      });
    }

    const currentTimeslot = table.timeslots.find((timeslot) => timeslot.time === time) || { availableOffers: [] };

    tableDispatch([
      { type: "SET_AVAILABLE_OFFERS", data: currentTimeslot.availableOffers },
      { type: "SET_TIME", data: time }
    ]);
  };

  const handleDurationChange = ({ duration, nonInterraction = false }) => {
    if (!nonInterraction) {
      const [h, m] = table.time.split(":");
      const totalHours = Number(h) + Math.floor(duration / 60);
      const totalMinutes = Number(m) + (Number(duration) % 60);

      actions.sendAnalyticsData({
        hitType: "event",
        eventAction: "Selection",
        eventLabel: `End Time - ${totalHours
          .toString()
          .padStart(2, "0")}:${totalMinutes.toString().padStart(2, "0")}`,
      });
    }

    tableDispatch({
      type: "SET_DURATION",
      data: duration,
    });
  };

  const handleGetAvailability = () => {
    actions.sendAnalyticsData({
      hitType: "event",
      eventAction: "Submit",
      eventLabel: "Check Availability",
    });

    actions.checkAvailability();
  };

  const dateMeetsOverrides = useCallback((date, defaultStatus) => {  
    const openOverrides = table.overrides.filter(override => (override.available))
    const closedOverrides = table.overrides.filter(override => (!override.available))

    const openOverridesMet = openOverrides.filter(override => {
      const from = parse(override.dateFrom, "yyyy-MM-dd", new Date());
      const to = parse(override.dateTo, "yyyy-MM-dd", new Date());

      return (
        (isSameDay(from, date) || isAfter(date, from)) &&
        (isSameDay(from, to) || isBefore(date, to))
      );
    });

    const closedOverridesMet = closedOverrides.filter(override => {
      const from = parse(override.dateFrom, "yyyy-MM-dd", new Date());
      const to = parse(override.dateTo, "yyyy-MM-dd", new Date());

      return (
        isAfter(date, from) && isBefore(date, to)
      );
    });

    if(defaultStatus && closedOverridesMet.length === 0 ){
      return true;
    }
    if(!defaultStatus && openOverridesMet.length === 0 ) {
      return true;
    }
    return false;

  }, [table])

  const getStartDate = useCallback(() => {

    if (table.date || (!table.overrrides && !table.rules)) return null;

    let count = 0;
    let checkDate = new Date();
    let selected = null;
    
    const getIsDisabled = getEnabledDates(table.overrides, table.rules)[0];
        
    while (!selected && count < 365) {
      const defaultStatus = !getIsDisabled(checkDate);
      const meetsOverides = dateMeetsOverrides(checkDate, defaultStatus);

      if (meetsOverides) {
        selected = checkDate
      }

      count += 1
      checkDate = addDays(checkDate, 1)
    }

    if (!selected) {
      selected = new Date()
    }

    return selected;
  }, [table])

  return (
    <>
      {alertText && (
        <Text>
          {alertText}
        </Text>
      )}
      <Grid mobileFullWidth>
        <div>
          <Label>Date</Label>
          <Field value={(dateFormat(table.date) ? dateFormat(table.date) : 'Select Date')}>
            <Flatpickr
              defaultDate={table.date}
              enable={getEnabledDates(table.overrides, table.rules)}
              handleChange={handleDateChange}
              minDate={today}
              redraw={table.bookingType}
              startAtDate={getStartDate()}
            >
              <input type="text" />
            </Flatpickr>
          </Field>
        </div>
        <SmallField>
          <GuestField
            date={table.date}
            overrides={table.overrides}
            rules={table.rules}
            handleGuestsChange={handleGuestsChange}
            value={table.numPeople}
            width="small"
          />
        </SmallField>
        <SmallField>
          <StartTimeField
            apiEnabled={table.apiEnabled}
            bookingTypeId={table.bookingType}
            date={table.date}
            endpoint={setup.endpoint}
            numPeople={table.numPeople}
            rules={table.rules}
            tableDispatch={tableDispatch}
            timeslots={table.timeslots}
            value={table.time}
            venueId={table.venueId}
            handleTimeStartChange={handleTimeStartChange}
          />
        </SmallField>
        <SmallField>
          <EndTimeField
            date={table.date}
            endTimeslots={table.endTimeslots}
            maxDuration={table.maxDuration}
            minDuration={table.minDuration}
            tableDispatch={tableDispatch}
            time={table.time}
            value={table.duration}
            handleDurationChange={handleDurationChange}
          />
        </SmallField>
        <ButtonGroup>
          <Button
            active
            disabled={
              table.loading
              || !table.duration
              || !table.time
              || !table.date
              || !table.bookingType
            }
            onClick={handleGetAvailability}
          >
            Continue
          </Button>
        </ButtonGroup>
      </Grid>
      <Errors errors={table.errors} />

    </>
  );
};

export default BookingDetails;
