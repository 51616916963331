import React, { useContext } from "react";
import styled from "styled-components";
import { tableStore } from '../../../../store/table';

const Head = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 40px;
  border-top: 1px solid #969696;

  @media (max-width: 500px) {
    padding: 20px 0 20px 20px;
  }

  .number {
    span {
      font-size: 28px;
      line-height: 20px;
      color: black;
      margin-right: 50px;

      @media (max-width: 500px) {
        margin-right: 20px;
      }
    }
  }

  p {
    font-size: 21px;
    line-height: 28px;
    margin-bottom: 0;
  }

  .selectedText {
    margin-left: auto;
    
    p {
      font-family: "futura-pt";
      font-weight: medium;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0px;
      margin-left: 30px;
      color: #000000;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

  }
`;

const Edit = styled.span`
  font-family: "futura-pt";
  font-weight: light;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.7px;
  text-decoration: underline;
  color: #000000;
  margin-left: 30px;
  cursor: pointer;
`;


const StepHead = ({ number, title, selectedText, step, index }) => {

  const { tableDispatch } = useContext(tableStore);

  const editStep = () => {

    tableDispatch([
      { type: "SET_STEP", data: step },
      { type: "SET_STEP_INDEX", data: index },
    ]);

  }

  return (
    <Head>
      <div className='number'>
        <span>
          {number}
        </span>
      </div>
      <div>
        <p>
          {title}
        </p>
      </div>
      {selectedText && (
        <div className='selectedText'>
          <p>
            {selectedText}
            <Edit onClick={editStep}>EDIT</Edit>
          </p>
        </div>
      )}
    </Head>
  );
};

export default StepHead;
