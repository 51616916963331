import styled from "styled-components";

const Grid = styled.div`
  display: flex;
  margin: 0 -10px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    margin: ${props => props.mobileMargin || "0 -10px -20px"};
  }

  & > * {
    padding: 0 10px;
    min-width: 150px;

    @media (max-width: 600px) {
      flex: ${props => props.mobileFullWidth ? "auto" : 1};
      width: ${props => props.mobileFullWidth ? "100%" : "auto"};
      padding: 0 10px 20px;
    }
  }
`;

export default Grid;
