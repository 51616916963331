import React from "react";
import styled from "styled-components";

const StepItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Step = ({ children }) => {

  return (
    <StepItem>
      {children}
    </StepItem>
  );
};

export default Step;
