import React from 'react';
import ReactDOM from 'react-dom';

import 'flickity/css/flickity.css';

import OnPageApp from './onPage/App';


window.initOnPageWidget = () => {
  const onPageBookingElement = document.querySelector('#on-page-booking');

  if (onPageBookingElement) {

    const venueId = onPageBookingElement.dataset.venue;
    const {category, title, exclude} = onPageBookingElement.dataset;
        
    const excludeObject = JSON.parse(exclude)? JSON.parse(exclude).map(item => {
      return item.name;
    }) : [];

    import(/* webpackChunkName: 'flatpickr-light' */ "flatpickr/dist/themes/light.css");

    ReactDOM.render(
      <React.StrictMode>
        <OnPageApp
          endpoint={onPageBookingElement.dataset.endpoint}
          venueId={venueId}
          category={category}
          title={title}
          exclude={excludeObject}
        />
      </React.StrictMode>,
      onPageBookingElement
    );
  }
}

document.addEventListener('DOMContentLoaded', window.initOnPageWidget);