import styled from "styled-components";

const Wrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-bottom: 15px;
  min-height: 45px;

  .theme--light & {

		input, select {
    	border: 1px solid black;
		}
  }

  & input,
  & select {
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
    border: none;
    background: transparent;
    font-family: inherit;
    padding: 14px 15px 12px;
  }

  & input[type="text"],
  & input[type="email"] {
    opacity: 1;
    font-size: 14px;
    line-height: 29px;
    letter-spacing: 0.03em;

    &:focus + span {
      height: 29px;
    }
  }

  & input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }

	& input::placeholder {
		color: #969696;
		font-size: 13px;
		line-height: 21px;
	}

  & input[type="date"]::-webkit-inner-spin-button {
    z-index: 1;
  }

  & input[type="date"]::-webkit-clear-button {
    z-index: 1;
  }
`;

const FieldLarge = ({ children }) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
};

export default FieldLarge;
