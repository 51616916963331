import React from "react";
import styled from "styled-components";

const Body = styled.div`
  width: 100%;
  height: ${(props) =>
    props.active ? "auto" : "0"};
  padding: ${(props) =>
    props.active ? "40px 0" : "0"};
  border-top: ${(props) =>
    props.active ? "2px dashed #969696;" : "0"};
  overflow: ${(props) =>
    props.active ? "visible" : "hidden"};
  transition: all .25s linear;

`;

const StepBody = ({ active, children }) => {

  return (
    <Body active={active}>
      {children}
    </Body>
  );
};

export default StepBody;
