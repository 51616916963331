import React, { useContext, useEffect } from "react";
import styled from "styled-components";

import Errors from "../../../components/ui/Errors";
import { isEnquiry } from "../../../helper";
import { tableStore } from "../../../store/table";
import Text from "../../../components/ui/Text";

const Wrapper = styled.div`
  max-width: 408px;
  margin: 0 auto;
`;

const Title = styled.h3`
  color: white;
  font-size: 14px;
  margin: 0;
  text-transform: uppercase;

  .theme--light & {
    color: black;
  }
`;

const Confirmed = () => {
  const { table, actions } = useContext(tableStore);

  useEffect(() => {
    if (
      typeof window.dataLayer !== "undefined" &&
      table.bookingTypeTitle &&
      typeof table.numPeople === "number"
    ) {
      window.dataLayer.push({
        event: "ga.event",
        category: "DMN Callback",
        action: isEnquiry(table.check) ? "Enquiry" : "Booking",
        label: table.bookingTypeTitle,
        value: table.numPeople
      });
    }

    if (typeof window.fbq !== "undefined") {
      window.fbq('track', 'Purchase', { currency: "GBP", value: 0.00 });
    }

    const eventLabelPostfix = window.location.search.includes("DMN-") ? " - Deposit" : ""
    actions.sendAnalyticsData({
      hitType: "event",
      eventAction: "Complete",
      eventLabel: `${isEnquiry(table.check) ? "Enquiry" : "Booking"}${eventLabelPostfix} | ${table.bookingTypeTitle}`,
    });
  }, []);

  return (
    <>
      <Wrapper>
        <Title>{isEnquiry(table.check) ? "Enquiry Received" : "Booking Confirmed"}</Title>
        <Text>
          {isEnquiry(table.check) ? "Thank you for your enquiry, our team will be in touch to confirm." : "Thank you for your booking, we look forward to welcoming you."}
        </Text>
        <Errors errors={table.errors} />
      </Wrapper>
    </>
  );
};

export default Confirmed;
