import React, { useContext, useMemo } from "react";

import BookingDetails from '../BookingDetails/BookingDetails';
import BookingType from '../Bookingtype/BookingType';
import Details from '../Details/Details';
import { dateFormat } from "../../../helper";
import Packages from '../Packages/Packages';
import Step from './Step/Step';
import StepBody from './Step/StepBody';
import StepHead from './Step/StepHead';
import { tableStore } from '../../../store/table';
import Confirmed from '../Confirmed/Confirmed';

const Steps = ({ type, exclude }) => {

  const {
    table,
  } = useContext(tableStore);

  const steps = {
    bookingDetails: "Booking Details",
    confirmed: "Confirmed",
    details: "Your Details",
    packages: "Additional Packages",
    start: "Booking Type",
  };

  const currentStep = steps[table.step];

  const bookingDetailsValues = useMemo(() => {
    if (table.date && table.numPeople && table.time && table.duration) {
      return `${dateFormat(table.date)} ${table.numPeople} Guests ${table.time}`;
    }
    return "";
  }, [table.date, table.numPeople, table.time, table.duration])


  const packageDetailsValues = useMemo(() => {
    if (table.packages) {
      return table.packages.find((item) => item.id === table.package);
    }
    return " ";
  }, [table.package])


  return (
    <>
      <Step>
        <StepHead number={1} title="Booking Type" selectedText={table.bookingTypeTitle} step="start" index={0} />
        <StepBody active={currentStep === "Booking Type"}>
          <BookingType type={type} exclude={exclude} />
        </StepBody>
      </Step>

      <Step>
        <StepHead number={2} title="Booking Details" selectedText={bookingDetailsValues} step="bookingDetails" index={1} />
        <StepBody active={currentStep === "Booking Details"}>
          <BookingDetails />
        </StepBody>
      </Step>

      <Step>
        <StepHead number={3} title="Additional Packages" selectedText={packageDetailsValues.title} step="packages" index={2} />
        <StepBody active={currentStep === "Additional Packages"}>
          <Packages />
        </StepBody>
      </Step>

      <Step>
        <StepHead number={4} title="Your Details" step="details" index={3} />
        <StepBody active={currentStep === "Your Details"}>
          <Details />
        </StepBody>
      </Step>

      {currentStep === "Confirmed" && (
        <Step>
          <StepHead number={5} title="Confirmation" step="confirmed" />
          <StepBody active={currentStep === "Confirmed"}>
            <Confirmed />
          </StepBody>
        </Step>
      )}

    </>
  );
};

export default Steps;
