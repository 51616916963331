import styled from "styled-components";
import Button from '../../../components/ui/Button';
import PackageHero from "./PackageHero";
import React from "react";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.p`
  display: block;
  margin: 0 0 10px;
  font-family: "futura-pt";
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.8px;
  color: #000;
  text-align: center;
`;

const SubTitle = styled.p`
  font-family: "futura-pt";
  text-align: center;
  margin-top: -5px;
  margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
  padding: 3px 5px;
  width: 125px;
  text-transform: none;
  background: ${(props) =>
    props.active ? "#000" : "transparent"};
  border-color: #000;
  color: ${(props) =>
    props.active ? "#fff" : "#000"};

  &:hover {  
    background: #000;
    color: #fff;
  }
`;

const Package = ({ active, click, data, hasOverlayText, roundel }) => {

  console.log(data);
  const {
    title,
    subtitle
  } = data;

  return (
    <Wrapper>
      <PackageHero active={active} click={click} data={data} roundel={roundel} hasOverlayText={hasOverlayText} />
      {!hasOverlayText && (
        <>
          <Title color="black">{title}</Title>
          {subtitle && <SubTitle>{subtitle}</SubTitle>}
        </>
      )}
      <StyledButton
        active={active}
        onClick={() => click(data)}
      >
        {active ? "Selected" : "Select"}
      </StyledButton>
    </Wrapper>
  );
};

export default Package;
