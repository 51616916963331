import React, { useContext } from "react";
import styled from "styled-components";

import { dateFormat, isEnquiry, sendAnalyticsGA4 } from "../../../helper";
import { tableStore } from "../../../store/table";
import Button from "../../../components/ui/Button";
import Errors from "../../../components/ui/Errors";
import FieldLarge from "../ui/FieldLarge";

const Wrapper = styled.div`
  max-width: 408px;
  margin-bottom: 30px;
`;

const Title = styled.h3`
  color: white;
  font-size: 14px;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 20px;

  .theme--light & {
    color: black;
  }
`;

const Subtitle = styled.h4`
  color: white;
  font-size: 14px;
  margin: 15px 0 5px;
  font-weight: normal;
  font-family: inherit, sans-serif;

  .theme--light & {
    color: black;
  }
`;

const Label = styled.label`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.7px;
  margin-bottom: 5px;
  font-family: inherit, sans-serif;
`;

const TextArea = styled.textarea`
  width: 100%;
  border: 0;
  min-height: 100px;
  padding: 10px;
  font-family: inherit, sans-serif;

  .theme--light & {
    border: 1px solid black;
    border-radius: 0;
  }
`;

const CheckBox = styled.div`
  display: flex;
  align-items: flex-start;

  & input {
    flex-shrink: 0;
    margin: 2px 0 0;
    border: 1px solid white;

    .theme--light & {
      border: 1px solid black;
    }
  }

  & label {
    color: white;
    font-size: 12px;
    line-height: 19px;
    margin-left: 10px;
    font-family: inherit, sans-serif;

    .theme--light & {
      color: black;
    }
  }
`;

const Details = () => {
  const { table, tableDispatch, actions } = useContext(tableStore);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (
            table?.bookingTypeTitle &&
            document.getElementById("on-page-booking") &&
            typeof window.dataLayer !== 'undefined'
          ) {
            const eventNow = isEnquiry(table?.check) ? "booking_form_enquiry" : "booking_form_confirm";
            const dataCategory = document.getElementById("on-page-booking").getAttribute('data-category') || null;
            sendAnalyticsGA4(table, eventNow, dataCategory, "BookForm");
          }

          if (typeof table.check === "object" && !table.check.canBookViaApi && table.check.webBookingUrl) {
            actions.sendAnalyticsData({
              hitType: "event",
              eventAction: "Click",
              eventLabel: "Christmas enquiry submission",
              eventCategory: "Pub page Christmas"
            });

            const { comments, email, firstName, lastName, phone } = table;

            const returnUrl = table.check.webBookingUrl + encodeURI(`&email=${email}&first_name=${firstName}&last_name=${lastName}&notes=${comments}&phone=${phone}&return_url=${window.location.origin}${window.location.pathname}?modal=booking`);

            window.location = returnUrl;

            return;
          }

          actions.completeBooking();
        }}
      >
        <Wrapper>
          <Title>Your Details</Title>
          <FieldLarge
            value={table.firstName}
            hideValue
          >
            <Label>First Name*</Label>
            <input
              required
              value={table.firstName}
              type="text"
              placeholder='First Name'
              onChange={(e) => {
                tableDispatch({
                  type: "SET_FIRST_NAME",
                  data: e.target.value,
                });
              }}
            />
          </FieldLarge>
          <FieldLarge
            value={table.lastName}
            hideValue
          >
            <Label>Last Name*</Label>
            <input
              required
              value={table.lastName}
              type="text"
              placeholder='Last Name'
              onChange={(e) => {
                tableDispatch({
                  type: "SET_LAST_NAME",
                  data: e.target.value,
                });
              }}
            />
          </FieldLarge>
          <FieldLarge
            value={table.phone}
            hideValue
          >
            <Label>Phone Number*</Label>
            <input
              required
              value={table.phone}
              type="text"
              placeholder='Phone Number'
              onChange={(e) => {
                tableDispatch({
                  type: "SET_PHONE",
                  data: e.target.value,
                });
              }}
            />
          </FieldLarge>
          <FieldLarge value={table.email} hideValue>
            <Label>Email*</Label>

            <input
              required
              value={table.email}
              type="email"
              placeholder='Email'
              onChange={(e) => {
                tableDispatch({
                  type: "SET_EMAIL",
                  data: e.target.value,
                });
              }}
            />
          </FieldLarge>
          <FieldLarge value={dateFormat(table.dob)}>
            <Label>DOB</Label>

            <input
              value={table.dob}
              type="date"
              onChange={(e) => {
                tableDispatch({
                  type: "SET_DOB",
                  data: e.target.value,
                });
              }}
            />
          </FieldLarge>
          <CheckBox>
            <input
              checked={table.promo}
              type="checkbox"
              onChange={(e) => {
                tableDispatch({
                  type: "SET_PROMO",
                  data: e.target.checked,
                });
              }}
            />
            <label>
              Sign me up for offers, news and promotions, mainly via email
            </label>
          </CheckBox>
          <Subtitle>Additional Comments?</Subtitle>
          <TextArea
            value={table.comments}
            onChange={(e) => {
              tableDispatch({
                type: "SET_COMMENTS",
                data: e.target.value,
              });
            }}
          />
          <Subtitle>Booking Policy</Subtitle>
          <CheckBox>
            <input
              required
              checked={table.policy}
              type="checkbox"
              onChange={(e) => {
                tableDispatch({
                  type: "SET_POLICY",
                  data: e.target.checked,
                });
              }}
            />
            <label
              dangerouslySetInnerHTML={{
                __html:
                  table.check?.dateSettings?.bookingPolicy ||
                  "Please note all tables are held for 15 minutes only.",
              }}
            />
          </CheckBox>
        </Wrapper>
        <Errors errors={table.errors} />
        <Button active disabled={table.loading}>
          {isEnquiry(table.check) ? "Enquire" : "Confirm Booking"}
        </Button>
      </form>
    </>
  );
};

export default Details;
