import React, { useContext } from "react";
import styled from "styled-components";

import { tableStore } from "../../../store/table";
import Button from "../../../components/ui/Button";
import ButtonGroup from "../../../components/ui/ButtonGroup";
import GridWrap from "../ui/GridWrapper";
import Package from "../ui/Package";
import Text from "../../../components/ui/Text";

const Description = styled(Text)`
  font-weight: normal;
  margin-top: 10px;
  opacity: 0.6;
  font-size: 16px;
  line-height: 24px;
  max-width: 430px;
`;

const Packages = () => {
  const { actions, table, tableDispatch } = useContext(tableStore);

  const handleNextStep = () => tableDispatch(
    { type: "SET_STEP", data: "details" },
    { type: "SET_STEP_INDEX", data: 2 }
  );

  const setPackage = (data) => {
    if (data.id === table.package) {
      tableDispatch({ type: "SET_PACKAGE", data: null });
    }
    actions.sendAnalyticsData({
      hitType: "event",
      eventAction: "Selection",
      eventLabel: `Packages - ${data.title}`,
    });
    tableDispatch({ type: "SET_PACKAGE", data: data.id });
  };

  return (
    <>
      {table.packages && (
        <>
          <GridWrap>
            {Array.from(table.packages, (data) => (
              <Package
                key={data.id}
                data={data}
                click={setPackage}
                active={table.package === data.id}
                hasOverlayText
              />
            ))}
          </GridWrap>
          <Description>
            When selecting a package this will be ready for you on arrival.
            Any changes must be made with 72 hours of your booking.
          </Description>
          <ButtonGroup>
            <Button
              active
              // disabled={isMandatory && !table.package}
              onClick={handleNextStep}
            >
              Next Step
            </Button>
          </ButtonGroup>
        </>
      )}
    </>

  );
};

export default Packages;
