import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import GridWrapper from '../ui/GridWrapper';
import { tableStore } from '../../../store/table';
import Package from '../ui/Package';


const BookingType = ({type, exclude}) => {

  const { table, tableDispatch, setup, actions } =
    useContext(tableStore);

  const { stepCache: { BookingType: cache = { isActive: true, BookingTypes: [] } } } = table;

  const [options, setOptions] = useState(cache.BookingTypes);

  const filteredOptions = useMemo(() => {
  
    return options.filter(option => {
      return !exclude.includes(option.title)
    });
  }, [options, exclude])

  const setBookingType = (data) => {
    const { overrides, packageMandatory, rules } = data;

    tableDispatch([
      { type: "SET_API_ENABLED", data: false },
      { type: "SET_BOOKING_TYPE", data },
      {
        type: "SET_MANDATORY_PACKAGES",
        data: packageMandatory
      },
      {
        type: "SET_OVERRIDES",
        data: overrides
      },
      { type: "SET_PACKAGES", data: data.packages },
      {
        type: "SET_RULES",
        data: rules
      },
      { type: "SET_STEP", data: 'bookingDetails' },
    ]);

    actions.sendAnalyticsData({
      hitType: "event",
      eventAction: "Click",
      eventLabel: `${type}`,
      eventCategory: "Pub page"
    });

  };

  const load = useCallback(() => {
    fetch(`${setup.endpoint}/v1/venue/${table.venueId}/setup`, {
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {

        if (data?.categories?.length) {

          const categories = data?.categories?.find(({ title }) => title === type).bookingTypes;
          
          setOptions(
            (prevOptions) => ([
              ...prevOptions, ...categories
            ])
          )
        }
      });
  }, [setBookingType, setup.endpoint, table, table.bookingTypeCategory, table.venueId]);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GridWrapper>
      {options &&
        filteredOptions.map((option) => (
          <Package
            active={table.bookingType === option.id}
            click={setBookingType}
            data={option}
            key={option.id}
          />
        ))}
    </GridWrapper>
  );
};

export default BookingType;
