import styled from "styled-components";
import Grid from "./Grid";

const GridWrapper = styled(Grid)`
  width: calc(100% + 20px);
  flex-wrap: wrap;
  margin: 0 -10px ${props => props.marginBottom || 0};
  justify-content: center;

  & > * {
    width: 20%;
    margin-bottom: 20px;

    @media (max-width: 640px) {
      width: 50%;
      max-width: 100%;
    }
  }
`;

export default GridWrapper;
